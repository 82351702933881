import React from 'react'
import styled from 'styled-components'
import { media } from '@foundation-lib/css'
import Image from './Image'
import Text from './Text'

const Container = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 70px auto;
  padding: 0 30px;
  ${media('only screen')
    .rule('margin')
    .maxWidths({
      '1200px': '60px auto',
      '1000px': '50px auto',
      '600px': '40px auto',
    })}
  @media only screen and (max-width: 1400px) {
    padding: 0;
  }
`

export default ({ image, imageVertPos, title, description, caption }) => (
  <Container>
    <Image src={image} vertPos={imageVertPos} alt="" />
    <Text {...{ title, description, caption }} />
  </Container>
)
