import React from 'react'
import styled from 'styled-components'
import { media } from '@foundation-lib/css'

import BaseLayout from '../components/layouts/BaseLayout'
import SEO from '../components/Seo'
import HeroSection from '../components/HeroSection'
import PageTitle from '../components/PageTitle'

import paintingBrothers from '../images/painting-brothers.jpg'
import paintingKaangChinWoman from '../images/painting-kaang-chin-woman.jpg'
import paintingMarket from '../images/painting-market.jpg'
import FormatsToBuy from '../components/FormatsToBuy'

const Section = styled.div`
  width: 100%;
  max-width: 1040px;
  margin: 70px auto;
  ${media('only screen').rule('margin').maxWidths({
    '1200px': '60px auto',
    '1000px': '50px auto',
    '600px': '40px auto',
  })}
  padding: 0 30px;
  @media only screen and (max-width: 1000px) {
    padding: 0 20px;
  }
  @media only screen and (max-width: 800px) {
    padding: 0 15px;
  }
  &:last-child {
    margin-bottom: 100px;
    @media only screen and (max-width: 1200px) {
      margin-bottom: 90px;
    }
    @media only screen and (max-width: 1000px) {
      margin-bottom: 80px;
    }
    @media only screen and (max-width: 600px) {
      margin-bottom: 70px;
    }
  }
`

const IndexPage = () => (
  <BaseLayout>
    <SEO title="Home" />

    <div>
      <PageTitle />
      <HeroSection
        image={paintingBrothers}
        title="A compelling story"
        description={
          <p>
            In the book <strong>“Myanmar Gold”</strong>, you will read the
            compelling story of the Chin People, an entire tribe in Myanmar,
            numbering in the millions, who were completely converted to Christ
            by American Christians. (Myanmar is more widely known as Burma.)
          </p>
        }
        caption="Award-winning author M. A. Robinette walks &amp; talks with Pastor Naing
          Thang."
      />
      <HeroSection
        image={paintingKaangChinWoman}
        title="A people and a hero"
        description={
          <p>
            In the book, we will introduce a particular group among the Chin,
            the Kaang Chin, as well as a living hero of the faith who was born
            among them as their conversion from the darkness of Animism began.
            This great man of God, Pastor Naing Thang, was called from among the
            Kaang Chin when he was just a boy living in a mountain village, that
            to this day, is still very primitive and remote. Our story gives a
            unique glimpse into the interesting and strange lives of the Kaang
            Chin. You will see how God used willing men and women to dispel the
            darkness of witchcraft as the light of the Gospel overtook evil.
          </p>
        }
        caption="A Kaang Chin woman proudly wears the colors of her people."
      />
      <HeroSection
        image={paintingMarket}
        title="Adventures in an exotic land"
        description={
          <>
            <p>
              This deeply engaging book contains exotic stories of encounters
              with tigers, bears, pythons, headhunters and even drug lords, and
              was written from a collection of several years of first-hand
              accounts from dozens of locals throughout Myanmar. Written by the
              two-time Associated Press award-winning writer, M.A. Robinette,
              who is also the founder of Mission To Myanmar, his work was
              completed with the rigorous standards of the news trade.
            </p>
            <p>
              To learn more about our organization go to
              www.MissionToMyanmar.com
            </p>
          </>
        }
        caption="These and more original paintings by Burmese artist Zayer Lin are featured in Myanmar Gold."
      />
      <Section>
        <FormatsToBuy />
      </Section>
    </div>
  </BaseLayout>
)

export default IndexPage
