import React from 'react'
import styled from 'styled-components'
import { useCartItemList } from '@foundations-of-grace-pub/checkout'
import { Icon } from '@foundation-lib/ui'
import { faCartPlus } from '@foundation-lib/ui/icons/solid'
import BodyButton from './BodyButton'
import availableItems from '../data/items'

const FormatsContainer = styled.div`
  display: flex;

  & > div + div {
    margin-left: 30px;
  }

  h3 {
    margin-top: 0px;
    margin-bottom: 10px;

    span {
      font-weight: normal;
      font-size: 16px;
    }
  }

  @media (max-width: 600px) {
    h3 {
      font-size: 14px;

      span {
        font-size: inherit;
      }
    }
  }
`

const AddToCartButton = styled(BodyButton)`
  svg {
    display: none;
  }

  @media (max-width: 600px) {
    span {
      display: none;
    }
    svg {
      display: inline;
    }
  }
`

const Format = ({ title, type, description }) => {
  const sendCartItemListAction = useCartItemList(s => s.action)

  const addTypeToCart = () => {
    sendCartItemListAction({
      action: 'add',
      item: { ...availableItems.find(item => item.type === type), qty: 1 },
    })
  }

  return (
    <div>
      <h3>{title}</h3>
      <AddToCartButton size="medium" onClick={() => addTypeToCart(type)}>
        <Icon icon={faCartPlus} />
        <span>Add to cart</span>
      </AddToCartButton>
      {description && (
        <p style={{ marginTop: '8px', fontSize: '14px' }}>{description}</p>
      )}
    </div>
  )
}

const FormatsToBuy = () => {
  return (
    <FormatsContainer id="buyTheBook">
      <Format title="Hardcover" type="hardcover" />
      <Format title="Audiobook" type="audiobook" />
      <Format title="eBook" type="ebook" />
    </FormatsContainer>
  )
}

export default FormatsToBuy
