import React from 'react'
import styled from 'styled-components'
import { media } from '@foundation-lib/css'
import useTitleMetadata from '../lib/hooks/useTitleMetadata'

const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 70px auto;
  padding: 0 30px;
  box-sizing: border-box;
  ${media('only screen')
    .rule('margin')
    .maxWidths({
      '1200px': '60px auto',
      '1000px': '50px auto',
      '600px': '40px auto',
    })}
  @media only screen and (max-width: 1000px) {
    padding: 0 20px;
  }
  @media only screen and (max-width: 800px) {
    padding: 0 15px;
  }
`

const Title = styled.h1`
  margin: 0 0 20px 0;
  font-family: 'Rajdhani', sans-serif;
  font-size: 160px;
  line-height: 0.9;
  text-transform: uppercase;
  color: #daa900;
  ${media('only screen')
    .rule('font-size')
    .maxWidths({
      '800px': '120px',
      '600px': '100px',
      '500px': '80px',
      '400px': '60px',
    })}
`

const Subtitle = styled.h2`
  margin: 0;
  font-family: 'Laila', serif;
  font-size: 36px;
  line-height: 1.5;
  font-weight: normal;
  color: #305a3d;
  ${media('only screen')
    .rule('font-size')
    .maxWidths({
      '800px': '32px',
      '600px': '28px',
      '450px': '24px',
    })}
`

export default () => {
  const { description } = useTitleMetadata()

  return (
    <Container>
      <Title>
        Myanmar
        <br />
        Gold
      </Title>
      <Subtitle>{description}</Subtitle>
    </Container>
  )
}
