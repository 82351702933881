import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 700px;
  overflow: hidden;
  box-sizing: border-box;
  border: 1px solid #d1cac0;
  border-radius: 10px;
  @media only screen and (max-width: 1400px) {
    border: none;
    border-radius: 0;
  }
  @media only screen and (max-width: 1200px) {
    height: 600px;
  }
  @media only screen and (max-width: 1000px) {
    height: 500px;
  }
  @media only screen and (max-width: 800px) {
    height: 400px;
  }
  @media only screen and (max-width: 600px) {
    height: 300px;
  }
  @media only screen and (max-width: 450px) {
    height: 225px;
  }
`

const Image = styled.img`
  width: 100%;
  height: auto;
  ${({ vertPos }) =>
    vertPos &&
    `
    transform: translate(0%, ${vertPos});
  `}
`

export default ({ src, vertPos, ...other }) => (
  <Container>
    <Image src={src} vertPos={vertPos} {...other} />
  </Container>
)
