import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1040px;
  margin: 60px auto 0 auto;
  padding: 0 30px;
  @media only screen and (max-width: 1200px) {
    margin-top: 50px;
  }
  @media only screen and (max-width: 1000px) {
    margin-top: 40px;
    padding: 0 20px;
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    margin-top: 0;
    padding: 0 15px;
  }
`

const Main = styled.div`
  flex: 1 1 auto;
  padding-right: 30px;
  font-family: 'Open Sans', sans-serif;
  font-size: 22px;
  line-height: 1.5;
  color: #555555;
  p {
    margin-top: 0;
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  & strong {
    font-weight: 700;
  }
  @media only screen and (max-width: 1000px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 800px) {
    order: 2;
    width: 100%;
    padding-right: 0;
    font-size: 18px;
  }
  @media only screen and (max-width: 600px) {
    font-size: 16px;
  }
`

const Title = styled.h2`
  margin: 0 0 20px 0;
  font-family: 'Laila', serif;
  font-size: 48px;
  line-height: 1.2;
  color: #305a3d;
  @media only screen and (max-width: 800px) {
    margin-top: 40px;
    margin-bottom: 15px;
    font-size: 42px;
  }
  @media only screen and (max-width: 600px) {
    margin-top: 30px;
    font-size: 36px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 32px;
  }
`

const Caption = styled.div`
  flex: 0 0 auto;
  width: 160px;
  padding-top: 15px;
  border-top: 3px solid #daa900;
  font-family: 'Rajdhani', sans-serif;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #a59c90;
  @media only screen and (max-width: 800px) {
    order: 1;
    width: 100%;
    margin-top: 15px;
    padding-top: 0;
    padding-left: 15px;
    border-top: none;
    border-left: 3px solid #daa900;
  }
`

export default ({ title, description, caption }) => (
  <Container>
    <Main>
      <Title>{title}</Title>
      {description}
    </Main>
    <Caption>{caption}</Caption>
  </Container>
)
